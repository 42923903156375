<template>
  <b-sidebar
    v-if="isSidebarActive"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          OIC List Detail
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="OicListDetailForm">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="oicList.oicName"
            name="OIC Name"
            label="OIC Name"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="oicList.address"
            name="oicListAddress"
            label="Address"
            :rules="{ required: false }"
            trim />
          <AddressField
            v-model="oicList.subDistrict"
            label="SubDistrict"
            type="sub-district"
            @select="onSelectAddress($event)" />
          <AddressField
            v-model="oicList.district"
            label="District"
            type="district"
            @select="onSelectAddress($event)" />
          <AddressField
            v-model="oicList.province"
            label="Province"
            type="province"
            @select="onSelectAddress($event)" />
          <AddressField
            v-model="oicList.zipcode"
            label="ZipCode"
            type="postcode"
            @select="onSelectAddress($event)" />
          <TextFieldInput
            v-model="oicList.phoneNumber"
            name="oicListPhoneNumber"
            label="Phone Number"
            :rules="{ required: false }"
            trim />
          <StatusRadioButton :status.sync="oicList.status" />
          <b-row>
            <b-col>
              Created At : {{ oicList.createdAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Created By : {{ oicList.createdBy.username }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated At : {{ oicList.updatedAt | formatDateTimeEn }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Updated By : {{ oicList.updatedBy.username }}
            </b-col>
          </b-row>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit"
              :disabled="compareOldValue">
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              :disabled="compareOldValue"
              @click="resetForm()">
              Cancel
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon ml-auto"
              :disabled="!compareOldValue"
              @click="deleteItem ()">
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import StatusRadioButton from '@/components/Form/StatusRadioButton.vue'
import AddressField from '@/components/Form/AddressField.vue'

export default {
  components: {
    TextFieldInput,
    StatusRadioButton,
    AddressField
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    initialOicList: {
      type: Object,
      required: true
    },
    selectedItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    oicList: {
      get () {
        return this.selectedItem
      },
      set (val) {
        this.$emit('update:selected-item', val)
      }
    },
    compareOldValue () {
      return JSON.stringify(this.oicList) === JSON.stringify(this.initialOicList)
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      const payload = {
        id: this.oicList.id,
        oicName: this.oicList.oicName,
        address: this.oicList.address,
        subDistrict: this.oicList.subDistrict,
        district: this.oicList.district,
        province: this.oicList.province,
        zipcode: this.oicList.zipcode,
        phoneNumber: this.oicList.phoneNumber,
        status: this.oicList.status
      }
      this.$emit('edit-oic-list', payload)
    },
    resetForm () {
      this.oicList = { ...this.initialOicList }
      this.$refs.OicListDetailForm.reset()
    },
    deleteItem () {
      this.$emit('delete-item', {
        ID: this.oicList.id,
        oicName: this.oicList.oicName
      })
    },
    onSelectAddress (address) {
      this.oicList.subDistrict = address?.subDistrict || ''
      this.oicList.district = address?.district || ''
      this.oicList.province = address?.province || ''
      this.oicList.zipcode = address?.postcode || ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
