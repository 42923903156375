<template>
  <div v-if="isLoaded">
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <OicListAdd
      ref="oicListAdd"
      :is-sidebar-active.sync="isSidebarActive"
      @add-oic-list="addOicList($event)" />
    <OicListDetail
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-oic-list="initialOicList"
      :selected-item.sync="selectedItem"
      @delete-item="openDeleteModal($event)"
      @edit-oic-list="editOicList($event)" />
    <OicListFillter
      :filter.sync="filter"
      :status-filter.sync="filter.status"
      @update:search-name="filter.searchName=$event"
      @update:search-phone="filter.searchPhone=$event"
      @update:search-address="filter.searchAddress=$event"
      @update:search-sub-district="filter.searchSubDistrict=$event"
      @update:search-district="filter.searchDistrict=$event"
      @update:search-province="filter.searchProvince=$event"
      @update:search-zipcode="filter.searchZipcode=$event"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add OIC List"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(oicName)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(address)="data">
          <div v-if="data && data.item && data.item.address">
            {{ data.item.address }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(subDistrict)="data">
          <div v-if="data && data.item && data.item.subDistrict">
            {{ data.item.subDistrict }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(district)="data">
          <div v-if="data && data.item && data.item.district">
            {{ data.item.district }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(province)="data">
          <div v-if="data && data.item && data.item.province">
            {{ data.item.province }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(zipcode)="data">
          <div v-if="data && data.item && data.item.zipcode">
            {{ data.item.zipcode }}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(phoneNumber)="data">
          <div v-if="data && data.item && data.item.phoneNumber">
            {{ data.item.phoneNumber }}
          </div>
          <div v-else>
            -
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import OicListFillter from '@/components/New/OicList/OicListFillter.vue'
import OicListAdd from '@/components/New/OicList/OicListAdd.vue'
import OicListDetail from '@/components/New/OicList/OicListDetail.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import OicListProvider from '@/resources/OicListProvider'

const OicListService = new OicListProvider()

export default {
  components: {
    OicListFillter,
    OicListAdd,
    DeleteModal,
    OicListDetail,
    TableTop
  },
  data () {
    return {
      isLoaded: false,
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchName: '',
        searchPhone: '',
        searchAddress: '',
        searchZipcode: '',
        searchSubDistrict: '',
        searchDistrict: '',
        searchProvince: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 0
      },
      fields: [
        { key: 'oicName', label: 'OIC Name', sortable: true },
        { key: 'address', label: 'Address' },
        { key: 'subDistrict', label: 'Sub District' },
        { key: 'district', label: 'District' },
        { key: 'province', label: 'Province' },
        { key: 'zipcode', label: 'Zipcode' },
        { key: 'phoneNumber', label: 'Phone Number' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialOicList: {},
      selectedItem: {},
      deleteItem: {}
    }
  },
  async created () {
    try {
      await this.getOicList()
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    fetchFilter () {
      this.setPagination(1)
      this.getOicList()
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    async editOicList (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await OicListService.update(val.id, payload)
        await this.getOicList()
        this.setInitialOicListAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 OIC List has been updated'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 OIC List has been Failed'
          }
        })
      }
    },
    rowClick (val) {
      this.setInitialOicListAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialOicListAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialOicList = { ...val }
    },
    async getOicList () {
      try {
        const page = this.$route?.query?.page || this.pagination?.page || 1
        const limit = this.$route?.query?.limit || this.pagination?.limit || 10
        const data = await OicListService.paginate(
          page,
          limit,
          {
            searchName: this.filter.searchName,
            searchPhone: this.filter.searchPhone,
            searchAddress: this.filter.searchAddress,
            searchZipcode: this.filter.searchZipcode,
            searchSubDistrict: this.filter.searchSubDistrict,
            searchDistrict: this.filter.searchDistrict,
            searchProvince: this.filter.searchProvince,
            status: this.filter.status
          }
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.log(err)
      }
    },
    async addOicList (val) {
      try {
        await OicListService.create(val)
        await this.getOicList()
        this.resetFormAndToggleSideBar(this.$refs.oicListAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 OIC List has been added'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 OIC List has been Failed'
          }
        })
      }
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await OicListService.softDelete(val.ID)
        await this.getOicList()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 OIC List has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 OIC List has been Failed ${error}`
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    sortFilter (ctx) {
      console.log(ctx)
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getOicList()
    },
    setPagination (page, totalDocs) {
      this.$router.replace(`oic-list?page=${page}`)
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
      this.pagination.page = page || this.pagination.page
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
