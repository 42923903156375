<template>
  <div>
    <b-sidebar
      :visible="isSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm()"
      @change="updateIsSidebarActive($event)">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Add New OIC List
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide" />
        </div>
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="OicListAddForm">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <TextFieldInput
              v-model="oicList.oicName"
              name="OIC Name"
              label="OIC Name"
              :rules="{ required: true }"
              trim />
            <TextFieldInput
              v-model="oicList.address"
              name="oicListAddress"
              label="Address"
              :rules="{ required: false }"
              trim />
            <AddressField
              v-model="oicList.subDistrict"
              label="SubDistrict"
              type="sub-district"
              @select="onSelectAddress($event)" />
            <AddressField
              v-model="oicList.district"
              label="District"
              type="district"
              @select="onSelectAddress($event)" />
            <AddressField
              v-model="oicList.province"
              label="Province"
              type="province"
              @select="onSelectAddress($event)" />
            <AddressField
              v-model="oicList.zipcode"
              label="ZipCode"
              type="postcode"
              @select="onSelectAddress($event)" />
            <TextFieldInput
              v-model="oicList.phoneNumber"
              name="oicListPhoneNumber"
              label="Phone Number"
              :rules="{ required: false }"
              trim />
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn-submit"
                type="submit">
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn-submit"
                variant="outline-secondary"
                @click="hide">
                Cancel
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import AddressField from '@/components/Form/AddressField.vue'

export default {
  components: {
    TextFieldInput,
    AddressField
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      initialOicList: {
        oicName: '',
        address: '',
        subDistrict: '',
        district: '',
        province: '',
        zipcode: '',
        phoneNumber: ''
      },
      oicList: {
        oicName: '',
        address: '',
        subDistrict: '',
        district: '',
        province: '',
        zipcode: '',
        phoneNumber: ''
      }
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-oic-list', this.oicList)
    },
    resetForm () {
      this.oicList = { ...this.initialOicList }
      this.$refs.OicListAddForm.reset()
    },
    onSelectAddress (address) {
      this.oicList.subDistrict = address?.subDistrict || ''
      this.oicList.district = address?.district || ''
      this.oicList.province = address?.province || ''
      this.oicList.zipcode = address?.postcode || ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
