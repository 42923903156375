import HttpRequest from './HttpRequest'

class OicListProvider extends HttpRequest {
  findAll () {
    return this.get('/oic-list')
  }

  findOne (id) {
    return this.get(`/oic-list/${id}`)
  }

  create (payload) {
    return this.post('/oic-list', payload)
  }

  update (id, payload) {
    return this.patch(`/oic-list/${id}`, payload)
  }

  softDelete (id) {
    return this.delete(`/oic-list/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/oic-list?page=${page}&limit=${limit}`, query)
  }
}

export default OicListProvider
